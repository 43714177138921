import React from 'react'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import Zoom from '@mui/material/Zoom'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@astronautsid/wpe-astro-ui/theme'
import { Close } from '@astronautsid/wpe-icons'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import Divider from '@mui/material/Divider'
import { DialogContent, DialogContentPropsType } from 'components/AstroUI/DialogContent'
import { Typography } from 'components/AstroUI/Typography'

import { TransitionProps } from '@mui/material/transitions'

export type CloseReasonType = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'

type EffectType = 'slide' | 'zoom'

export type DialogPropsType = Omit<MuiDialogProps, 'onClose'> & {
  onClose?: (reason: CloseReasonType) => void
  dividers?: boolean
  withHeader?: boolean
  backdropClick?: boolean
  titleProps?: React.CSSProperties
  effect?: EffectType
  dialogContentProps?: DialogContentPropsType
}

const TransitionZoom = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom {...props} ref={ref} />
})

const TransitionSlide = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide {...props} direction="left" ref={ref} />
})

export const DialogBase = styled(MuiDialog)<MuiDialogProps>`
  .MuiDialog-paper {
    border-radius: 8px;
  }
`

const Dialog = ({
  title,
  titleProps,
  dividers = true,
  withHeader = true,
  children,
  onClose,
  backdropClick = false,
  effect = 'zoom',
  dialogContentProps = {},
  ...rest
}: DialogPropsType) => {
  const theme = useTheme()

  return (
    <DialogBase
      keepMounted
      TransitionComponent={effect === 'zoom' ? TransitionZoom : TransitionSlide}
      onClose={(_, reason) => {
        if (!backdropClick && reason === 'backdropClick') return
        if (onClose) {
          onClose(reason)
        }
      }}
      {...rest}
    >
      {withHeader && (
        <Box
          className="dialog-title"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="16px 24px"
          style={titleProps}
        >
          <Typography variant="headline-default">{title}</Typography>

          {onClose ? (
            <IconButton onClick={() => onClose('closeButtonClick')}>
              <Close color={theme.palette.iconColor.dark} size={24} />
            </IconButton>
          ) : null}
        </Box>
      )}

      {dividers && <Divider />}

      <DialogContent {...dialogContentProps}>{children}</DialogContent>
    </DialogBase>
  )
}

export default Dialog

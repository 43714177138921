import { SELLING_POINT_POPUP } from 'config/sessionStorageKeys'

const DEFAULT_VALUE = {
  isShow: true,
}

export const setSellingPoinPopup = ({ isShow }: { isShow: boolean }) => {
  const payload = {
    isShow,
  }

  sessionStorage.setItem(SELLING_POINT_POPUP, JSON.stringify(payload))
}

export const getSellingPoinPopup = () => {
  const data = sessionStorage.getItem(SELLING_POINT_POPUP) || JSON.stringify(DEFAULT_VALUE)

  return JSON.parse(data) as typeof DEFAULT_VALUE
}

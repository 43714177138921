'use client'

import { useEffect, useState } from 'react'
import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import IconButton from '@mui/material/IconButton'
import { Close } from '@astronautsid/wpe-icons'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

import { Dialog } from 'components/AstroUI'
import {
  SELLING_POINT_BANNER_URL,
  SELLING_POINT_CLICK_URL_ANDROID,
  SELLING_POINT_CLICK_URL_IOS,
} from 'config/constants'
import { getSellingPoinPopup, setSellingPoinPopup } from 'app/(home)/_utils/serviceSessionStorage'
import useAppWebview from 'hooks/useAppWebview'
import { getUAFromCookie } from 'utils/helpers/getUAFromCookie'

const PopupSellingPoint = () => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()

  const { isAppWebview } = useAppWebview()

  const handleClose = () => {
    setSellingPoinPopup({ isShow: false })
    setOpen(false)
  }

  const handleClickBanner = () => {
    const { os } = getUAFromCookie()

    const parsedOS = os?.name?.toLocaleLowerCase()

    if (parsedOS === 'ios') {
      window.open(SELLING_POINT_CLICK_URL_IOS, '_blank')
    } else {
      window.open(SELLING_POINT_CLICK_URL_ANDROID, '_blank')
    }
  }

  useEffect(() => {
    const { device } = getUAFromCookie()

    if (isAppWebview || device.type !== 'mobile') return

    const { isShow } = getSellingPoinPopup()

    if (isShow) {
      setOpen(isShow)
    }
  }, [isAppWebview, setOpen])

  if (!SELLING_POINT_BANNER_URL.length) return null

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          background: 'transparent',
          margin: '0 20px',
        },
      }}
      dialogContentProps={{
        sx: {
          p: 0,
        },
      }}
      withHeader={false}
      dividers={false}
      backdropClick
    >
      <Box maxWidth="320px">
        <IconButton
          sx={{
            backgroundColor: '#FFF',
            width: '32px',
            height: '32px',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 0,
            marginLeft: 'auto',
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
          }}
          disableRipple
          onClick={handleClose}
        >
          <Close color={theme.palette.iconColor.default} size={20} />
        </IconButton>

        <Box>
          <img
            src={SELLING_POINT_BANNER_URL}
            alt="selling-point"
            style={{ maxWidth: '100%', width: '100%', height: 'auto' }}
            onClick={handleClickBanner}
          />
        </Box>
      </Box>
    </Dialog>
  )
}

export default PopupSellingPoint

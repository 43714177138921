import { useMemo } from 'react'
import { useSearchParams } from 'next/navigation'

const useAppWebview = () => {
  const searchParams = useSearchParams()

  const isAppWebview = useMemo(
    () => JSON.parse((searchParams.get('webview') as string) ?? 'false') ?? false,
    [searchParams],
  )

  return { isAppWebview }
}

export default useAppWebview

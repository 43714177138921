import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material'
import { ColorKeyPrefixType } from '@astronautsid/wpe-astro-ui/tokens/color'

export type TypographyPropsType = Omit<MuiTypographyProps, 'color'> & {
  /**
   * Include color from template.
   *
   * ex: 'galaxy.galaxy-8' | 'textColor.primaryDark' | etc
   * */
  color?: MuiTypographyProps['color'] | ColorKeyPrefixType
}

const Typography = (props: TypographyPropsType) => <MuiTypography {...props} />

export default Typography

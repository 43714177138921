import DialogContent, {
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material/DialogContent'

import { styled } from '@astronautsid/wpe-astro-ui/theme'

export type DialogContentPropsType = MuiDialogContentProps

const AstroDialogContent = styled(DialogContent)<DialogContentPropsType>``

export default AstroDialogContent
